const detailing = { path: "/detailing", title: "רמת גימור", showStep: true }
const color = { path: "/color", title: "צבע", showStep: true }
const upholstery = { path: "/upholstery", title: "ריפוד", showStep: true }
const accessories = { path: "/accessories", title: "אביזרים", showStep: true }
const summary = { path: "/summary", title: "סיכום ההזמנה", showStep: true }
const order = { path: "/order", title: "יצירת הזמנה", showStep: false }
const payment = { path: "/payment", title: "תשלום מקדמה", showStep: false }

export default {
  detailing,
  color,
  upholstery,
  accessories,
  summary,
  order,
  payment,
  array: [detailing, color, upholstery, accessories, summary, order, payment],
}
